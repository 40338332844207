import { get, isPlainObject } from 'lodash-es';
import { RSAA } from 'redux-api-middleware';

import { requestHeaders } from '../../utils/api';
import { setTransactionQuestionnaire } from '../Transaction/actions';
// import { setTransactionQuestionnaire } from '../Transaction/actions';
import { setNotice } from '../UI/actions';
import {
  setFeaturesForRoom,
  setLink,
  setRoom,
  setRoomAccessLog,
  setRoomCodes,
  setRoomDocuments,
  setRoomHistory,
  setRoomLinks,
  setRoomParticipants,
  setRoomToken,
  setUserRooms,
} from './actions';
import * as Types from './types';

// import { addProductToCart, setCart, emptyCart } from './actions';

export const fetchUserRooms = isLPPortal => {
  const REQUEST = Types[`FETCH_USER_ROOMS_REQUEST`];
  const SUCCESS = Types[`FETCH_USER_ROOMS_SUCCESS`];
  const FAILED = Types[`FETCH_USER_ROOMS_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/user/rooms',
        method: 'GET',
        headers: requestHeaders(true, 'user'),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!!actionResponse.error) {
      dispatch(
        setNotice({
          type: 'error',
          message:
            actionResponse.payload?.message ||
            'An error has occurred. If error persists after refresh, please contact Savvi Support.',
        }),
      );
      throw await new Error(actionResponse.payload?.message);
    }
    await dispatch(setUserRooms(actionResponse.payload));
    if (isLPPortal) {
      let lpRooms = [];
      let populatedLPRooms = [];
      let roomDocs = [];
      let ledgerReports = [];
      let ledgerDocs = [];
      actionResponse.payload.forEach(room => {
        if (room.type === 'report' || room.type === 'deal') {
          lpRooms.push(room);
        }
      });
      await Promise.allSettled(
        lpRooms.map(room => {
          return dispatch(fetchRoomByAccessCode(room.data_access_code)).then(
            p => p,
            err => {
              const error = new Error(err);
              console.error(err);
              throw error;
            },
          );
        }),
      ).then(results => {
        for (const { status, value } of results) {
          if (status === 'rejected') {
            return;
          }
          const room = lpRooms.find(
            r => r.data_access_code === value.room.data_access_code,
          );

          const {
            room: {
              account,
              account_id,
              data_access_code,
              documents = [],
              report_feature_types = [],
            } = {},
          } = value;

          populatedLPRooms.push({
            ...value.room,
            partner: room.partner,
            codes: room.codes,
          });
          if (documents) {
            for (const doc of documents) {
              roomDocs.push({
                ...doc,
                room_access_code: data_access_code,
                companyName: account?.name,
              });
            }
          }
          if (report_feature_types.length > 0) {
            report_feature_types.forEach(report => {
              let updatedResourceListData = {};
              Object.entries(report.resourceListData).forEach(([key, val]) => {
                // const updatedVal = val.map(v => ({ ...v, account: report.account }));
                let updatedVals = [];
                val.forEach(v => {
                  if (v.feature_type === 'document' && !!v.value) {
                    const roomDocIndex = roomDocs.findIndex(
                      roomDoc => roomDoc.box_file_id === v.value,
                    );
                    let updatedV = {
                      ...v,
                      companyName: account?.name,
                      box_file_id: v.value,
                      report,
                    };
                    if (roomDocIndex !== -1) {
                      updatedV = {
                        ...updatedV,
                        filename: v.filename || roomDocs[roomDocIndex].filename,
                        document: roomDocs[roomDocIndex],
                      };
                    }

                    const ledgerDocsIndex = ledgerDocs.findIndex(
                      doc => doc.name === updatedV.report.name,
                    );
                    if (ledgerDocsIndex === -1) {
                      ledgerDocs.push({ ...report, docs: [updatedV] });
                    } else {
                      ledgerDocs[ledgerDocsIndex].docs.push(updatedV);
                    }
                  }
                  updatedVals.push({ ...v, account });
                });
                updatedResourceListData[key] = updatedVals;
              });
              const ledgerReportsIndex = ledgerReports.findIndex(
                val => val.id === report.id,
              );
              if (ledgerReportsIndex === -1) {
                ledgerReports.push({
                  ...report,
                  resourceListData: updatedResourceListData,
                  accessCode: room.data_access_code,
                  roomId: room.id,
                  account_id,
                  account,
                });
              } else {
                ledgerReports[ledgerReportsIndex].resourceListData = {
                  ...ledgerReports[ledgerReportsIndex].resourceListData,
                  ...updatedResourceListData,
                };
              }
            });
          }
        }
      });
      // reportingRooms.forEach(async (room, ind) => {
      // for (const room of lpRooms) {
      // }

      return await {
        payload: actionResponse.payload,
        roomDocs,
        ledgerReports,
        ledgerDocs,
        populatedLPRooms,
      };
    }
    return await actionResponse.payload;
  };
};

export const fetchAccountRooms = accountId => {
  const REQUEST = Types[`FETCH_ACCOUNT_ROOMS_REQUEST`];
  const SUCCESS = Types[`FETCH_ACCOUNT_ROOMS_SUCCESS`];
  const FAILED = Types[`FETCH_ACCOUNT_ROOMS_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/admin/room?open=true',
        method: 'GET',
        headers: requestHeaders(true, accountId),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      return await actionResponse.payload;
    }
    if (actionResponse.payload.code === 'UNAUTHORIZED') {
      return await actionResponse.payload;
    }
    // dispatch(
    //   setNotice({
    //     type: 'error',
    //     message:
    //       actionResponse.payload?.message ||
    //       'An error has occurred. If error persists after refresh, please contact Savvi Support.',
    //   }),
    // );
    throw await new Error(actionResponse.payload?.message);
  };
};

export const fetchInspectAccessCode = access_code => {
  const REQUEST = Types[`FETCH_INSPECT_ACCESS_CODE_REQUEST`];
  const SUCCESS = Types[`FETCH_INSPECT_ACCESS_CODE_SUCCESS`];
  const FAILED = Types[`FETCH_INSPECT_ACCESS_CODE_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/public/access_code/inspect?access_code=${access_code}`,
        method: 'GET',
        headers: requestHeaders(),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    await dispatch(setRoom(actionResponse.payload, access_code));
    if (!actionResponse.error) {
      return await actionResponse.payload;
    }
    // await get(
    //   actionResponse,
    //   'payload.message',
    //   'This form has already been completed.  Please contact technical support if you believe this is in error.',
    // );
    throw await actionResponse.payload;
  };
};

export const fetchRoomByAccessCode = (access_code, account_id) => {
  const REQUEST = Types[`FETCH_ROOM_REQUEST`];
  const SUCCESS = Types[`FETCH_ROOM_SUCCESS`];
  const FAILED = Types[`FETCH_ROOM_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/guest/rooms/${access_code}`,
        method: 'GET',
        headers: requestHeaders(true, account_id, true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(setRoom(actionResponse.payload, access_code));
      if (actionResponse.payload.outside_forms.length > 0) {
        actionResponse.payload.outside_forms.forEach(form => {
          dispatch(
            setTransactionQuestionnaire(
              form,
              form.access_code || form.questionnaire?.access_code,
            ),
          );
        });
      }
      return await actionResponse.payload;
    }
    // await get(
    //   actionResponse,
    //   'payload.message',
    //   'This form has already been completed.  Please contact technical support if you believe this is in error.',
    // );
    const payload = {
      ...actionResponse.payload,
      room: { id: actionResponse.payload.room_id },
    };
    await dispatch(setRoom(payload, access_code));
    throw await payload;
  };
};

export const fetchRoomById = (roomId, account_id, accessCode, outsideForms = []) => {
  const REQUEST = Types[`FETCH_ROOM_BY_ID_REQUEST`];
  const SUCCESS = Types[`FETCH_ROOM_BY_ID_SUCCESS`];
  const FAILED = Types[`FETCH_ROOM_BY_ID_FAILED`];
  return async (dispatch, getState) => {
    let actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/room/${roomId}`,
        method: 'GET',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      actionResponse.payload = {
        ...actionResponse.payload,
        is_admin_user: true,
        outside_forms: [
          ...(actionResponse.payload?.outside_forms || []),
          ...outsideForms,
        ],
      };
      await dispatch(
        setRoom(
          actionResponse.payload,
          accessCode || actionResponse.payload.room.data_access_code,
        ),
      );
      if (actionResponse.payload.outside_forms.length > 0) {
        actionResponse.payload.outside_forms.forEach(form => {
          dispatch(setTransactionQuestionnaire(form, form.questionnaire.access_code));
        });
      }
      return await actionResponse.payload;
    }
    await get(
      actionResponse,
      'payload.message',
      'Unable to fetch Room. Please try logging back in or contact support if error persists',
    );
    // await dispatch(
    //   setRoom(actionResponse.payload, actionResponse.payload.data_access_code),
    // );
    throw await actionResponse.payload;
  };
};

export const fetchRoomByUser = roomId => {
  const REQUEST = Types[`FETCH_ROOM_BY_USER_REQUEST`];
  const SUCCESS = Types[`FETCH_ROOM_BY_USER_SUCCESS`];
  const FAILED = Types[`FETCH_ROOM_BY_USER_FAILED`];
  return async (dispatch, getState) => {
    let actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/user/rooms/${roomId}`,
        method: 'GET',
        headers: requestHeaders(true, 'user'),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      actionResponse.payload = { ...actionResponse.payload, is_admin_user: true };
      await dispatch(
        setRoom(actionResponse.payload, actionResponse.payload.room.data_access_code),
      );
      if (actionResponse.payload.outside_forms.length > 0) {
        actionResponse.payload.outside_forms.forEach(form => {
          dispatch(setTransactionQuestionnaire(form, form.questionnaire.access_code));
        });
      }
      return await actionResponse.payload;
    }
    await get(
      actionResponse,
      'payload.message',
      'Unable to fetch Room. Please try logging back in or contact support if error persists',
    );
    // await dispatch(
    //   setRoom(actionResponse.payload, actionResponse.payload.data_access_code),
    // );
    throw await actionResponse.payload;
  };
};

/**
 * body:
{
  "open": true,
  "require_auth":false,
  "strict":false,
  "label":"We're The Best Data Room Eva!!!"
}
 */
export const updateRoom = (
  body,
  roomId,
  account_id,
  accessCode,
  shouldfetchUpdatedRoom = true,
) => {
  const REQUEST = Types[`PUT_ROOM_REQUEST`];
  const SUCCESS = Types[`PUT_ROOM_SUCCESS`];
  const FAILED = Types[`PUT_ROOM_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/room/${roomId}`,
        method: 'PUT',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      dispatch(setNotice('Room Updated.'));
      if (shouldfetchUpdatedRoom) {
        dispatch(fetchRoomById(roomId, account_id, accessCode));
      }
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(actionResponse, 'payload.message', 'Failed to update room.'),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to update room.');
  };
};

export const fetchFeaturesForRoom = (key, query, account_id) => {
  const REQUEST = Types[`FETCH_FEATURES_FOR_ROOM_REQUEST`];
  const SUCCESS = Types[`FETCH_FEATURES_FOR_ROOM_SUCCESS`];
  const FAILED = Types[`FETCH_FEATURES_FOR_ROOM_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/feature${query || ''}`,
        method: 'GET',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(setFeaturesForRoom(actionResponse.payload, key));
      return await actionResponse.payload;
    }
    throw await actionResponse.payload;
    // throw await get(
    //   actionResponse,
    //   'payload.message',
    //   'This form has already been completed.  Please contact technical support if you believe this is in error.',
    // );
  };
};

export const createRoomFeatures = (features, baseBody, roomId, account_id) => {
  return async dispatch => {
    return features.reduce(async (previousPromise, feature) => {
      return await previousPromise.then(
        e =>
          dispatch(
            createRoomFeature(
              { ...baseBody, feature_type_id: feature.feature_type_id },
              roomId,
              account_id,
            ),
          ),
        error => {
          console.warn('error', error);
          return Promise.resolve();
        },
      );
    }, Promise.resolve(100));
  };
};

export const createRoomFeature = (body, roomId, account_id) => {
  /**
   * body:
    {
      "room_id":1,
      "feature_type_id":406,
      "resource_id":730,
      "resource_variable_name": "Bob",
      "resource_custom_label": "Blob"
    }
   */
  const REQUEST = Types[`POST_ROOM_FEATURE_REQUEST`];
  const SUCCESS = Types[`POST_ROOM_FEATURE_SUCCESS`];
  const FAILED = Types[`POST_ROOM_FEATURE_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/room/${roomId}/feature`,
        method: 'POST',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(actionResponse, 'payload.message', 'Failed to create room feature.'),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to create room feature');
  };
};

export const updateRoomFeatures = (resources, body, roomId, account_id) => {
  return async dispatch => {
    const features = Object.entries(resources).reduce((dict, [resourceKey, resource]) => {
      dict = [...dict, ...resource.features];
      return dict;
    }, []);
    return features.reduce(async (previousPromise, feature) => {
      return await previousPromise.then(
        e =>
          dispatch(updateRoomFeature(feature.room_feature_id, body, roomId, account_id)),
        error => {
          console.warn('error', error);
          return Promise.resolve();
        },
      );
    }, Promise.resolve(100));
  };
};

export const updateRoomFeature = (roomFeatureId, body, roomId, account_id) => {
  /**
   * body:
  {
    "resource_variable_name": "bobby",
    "resource_custom_label":"Bobby"
  }
   */
  const REQUEST = Types[`PUT_ROOM_FEATURE_REQUEST`];
  const SUCCESS = Types[`PUT_ROOM_FEATURE_SUCCESS`];
  const FAILED = Types[`PUT_ROOM_FEATURE_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/room/${roomId}/feature/${roomFeatureId}`,
        method: 'PUT',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(actionResponse, 'payload.message', 'Failed to update room feature.'),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to update room feature');
  };
};

export const deleteRoomFeatures = (collection, roomId, account_id) => {
  return async dispatch => {
    let features = collection;
    if (isPlainObject(collection)) {
      features = Object.entries(collection).reduce((dict, [resourceKey, resource]) => {
        dict = [...dict, ...resource.features];
        return dict;
      }, []);
    }
    return features.reduce(async (previousPromise, feature) => {
      return await previousPromise.then(
        e => dispatch(deleteRoomFeature(feature.room_feature_id, roomId, account_id)),
        error => {
          console.warn('error', error);
          return Promise.resolve();
        },
      );
    }, Promise.resolve(100));
  };
};

export const deleteRoomFeature = (roomFeatureId, roomId, account_id, accessCode) => {
  /**
   * body:
  {
    "room_id": 1,
  }
   */
  const REQUEST = Types[`DELETE_ROOM_FEATURE_REQUEST`];
  const SUCCESS = Types[`DELETE_ROOM_FEATURE_SUCCESS`];
  const FAILED = Types[`DELETE_ROOM_FEATURE_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/room/${roomId}/feature/${roomFeatureId}`,
        method: 'DELETE',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      dispatch(setNotice('Room field removed.'));
      if (accessCode) {
        await dispatch(fetchRoomById(roomId, account_id, accessCode));
      }
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(actionResponse, 'payload.message', 'Failed to delete room feature.'),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to delete room feature');
  };
};

export const fetchRoomLinks = (roomId, account_id) => {
  /**
   * body:
  {
    "room_id":1,
    "label": "Let there be light",
    "link": "https://bulbs.org"
  }
   */
  const REQUEST = Types[`FETCH_ROOM_LINKS_REQUEST`];
  const SUCCESS = Types[`FETCH_ROOM_LINKS_SUCCESS`];
  const FAILED = Types[`FETCH_ROOM_LINKS_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/room/${roomId}/link`,
        method: 'GET',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      dispatch(setRoomLinks(actionResponse.payload, roomId));
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(actionResponse, 'payload.message', 'Failed to create room link.'),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to create room link');
  };
};

export const createRoomLink = (body, roomId, account_id, accessCode) => {
  /**
   * body:
  {
    "room_id":1,
    "label": "Let there be light",
    "link": "https://bulbs.org"
  }
   */
  const REQUEST = Types[`POST_ROOM_LINK_REQUEST`];
  const SUCCESS = Types[`POST_ROOM_LINK_SUCCESS`];
  const FAILED = Types[`POST_ROOM_LINK_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/room/${roomId}/link`,
        method: 'POST',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      await dispatch(fetchRoomById(roomId, account_id, accessCode));
      dispatch(setNotice('Room field added.'));
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(actionResponse, 'payload.message', 'Failed to create room link.'),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to create room link');
  };
};

export const updateRoomLink = (body, roomLinkId, roomId, account_id, accessCode) => {
  /**
   * body:
  {
    "room_id":1
  }
   */
  const REQUEST = Types[`PUT_ROOM_LINK_REQUEST`];
  const SUCCESS = Types[`PUT_ROOM_LINK_SUCCESS`];
  const FAILED = Types[`PUT_ROOM_LINK_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/room/${roomId}/link/${roomLinkId}`,
        method: 'PUT',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      dispatch(setNotice('Room Link Updated.'));
      await dispatch(fetchRoomById(roomId, account_id, accessCode));
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(actionResponse, 'payload.message', 'Failed to update room link.'),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to update room link');
  };
};

export const deleteRoomLink = (roomLinkId, roomId, account_id, accessCode) => {
  const REQUEST = Types[`DELETE_ROOM_LINK_REQUEST`];
  const SUCCESS = Types[`DELETE_ROOM_LINK_SUCCESS`];
  const FAILED = Types[`DELETE_ROOM_LINK_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/room/${roomId}/link/${roomLinkId}`,
        method: 'DELETE',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(fetchRoomById(roomId, account_id, accessCode));
      dispatch(setNotice('Room Link Deleted.'));
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(actionResponse, 'payload.message', 'Failed to delete room link.'),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to delete room link');
  };
};

export const fetchRoomDocuments = (roomId, account_id) => {
  /**
   * body:
  {
    "room_id":1,
    "label": "Let there be light",
    "link": "https://bulbs.org"
  }
   */
  const REQUEST = Types[`FETCH_ROOM_DOCUMENTS_REQUEST`];
  const SUCCESS = Types[`FETCH_ROOM_DOCUMENTS_SUCCESS`];
  const FAILED = Types[`FETCH_ROOM_DOCUMENTS_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/room/${roomId}/document`,
        method: 'GET',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      dispatch(setRoomDocuments(actionResponse.payload, roomId));
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(actionResponse, 'payload.message', 'Failed to get room documents.'),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to get room documents');
  };
};

export const createRoomDocument = (body, roomId, account_id, accessCode) => {
  /**
   * body:
  {
    "room_id":1,
    "label": "Let there be light",
    "link": "https://bulbs.org"
  }
   */
  const REQUEST = Types[`POST_ROOM_DOCUMENT_REQUEST`];
  const SUCCESS = Types[`POST_ROOM_DOCUMENT_SUCCESS`];
  const FAILED = Types[`POST_ROOM_DOCUMENT_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/room/${roomId}/document`,
        method: 'POST',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      await dispatch(fetchRoomById(roomId, account_id, accessCode));
      dispatch(setNotice('Room Document Added.'));
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(actionResponse, 'payload.message', 'Failed to add room document.'),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to add room document');
  };
};

export const updateRoomDocument = (
  body,
  roomDocumentId,
  roomId,
  account_id,
  accessCode,
) => {
  /**
   * body:
  {
    "room_id":1,
    "label": "Let there be light",
    "link": "https://bulbs.org"
  }
   */
  const REQUEST = Types[`PUT_ROOM_DOCUMENT_REQUEST`];
  const SUCCESS = Types[`PUT_ROOM_DOCUMENT_SUCCESS`];
  const FAILED = Types[`PUT_ROOM_DOCUMENT_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/room/${roomId}/document/${roomDocumentId}`,
        method: 'PUT',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      await dispatch(fetchRoomById(roomId, account_id, accessCode));
      dispatch(setNotice('Room Document Updated.'));
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(
          actionResponse,
          'payload.message',
          'Failed to updated room document.',
        ),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to update room document');
  };
};

export const deleteRoomDocument = (roomDocumentId, roomId, account_id, accessCode) => {
  const REQUEST = Types[`DELETE_ROOM_DOCUMENT_REQUEST`];
  const SUCCESS = Types[`DELETE_ROOM_DOCUMENT_SUCCESS`];
  const FAILED = Types[`DELETE_ROOM_DOCUMENT_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/room/${roomId}/document/${roomDocumentId}`,
        method: 'DELETE',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(fetchRoomById(roomId, account_id, accessCode));
      dispatch(setNotice('Room Document Removed.'));
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(
          actionResponse,
          'payload.message',
          'Failed to remove room document.',
        ),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to remove room document');
  };
};

export const addRoomReport = (body, roomId, account_id, accessCode) => {
  /**
   * body:
  {
    "room_id": 1,
    "feature_type_id": 752
  }
   */
  const REQUEST = Types[`POST_ROOM_REPORT_REQUEST`];
  const SUCCESS = Types[`POST_ROOM_REPORT_SUCCESS`];
  const FAILED = Types[`POST_ROOM_REPORT_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/room/${roomId}/report`,
        method: 'POST',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      await dispatch(fetchRoomById(roomId, account_id, accessCode));
      dispatch(setNotice('Room Report Added.'));
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(actionResponse, 'payload.message', 'Failed to add room report.'),
      }),
    );
    let err = new Error(actionResponse.payload?.message || 'Failed to add room report');
    throw err;
  };
};

export const deleteRoomReport = (roomReportId, roomId, account_id, accessCode) => {
  const REQUEST = Types[`DELETE_ROOM_REPORT_REQUEST`];
  const SUCCESS = Types[`DELETE_ROOM_REPORT_SUCCESS`];
  const FAILED = Types[`DELETE_ROOM_REPORT_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/room/${roomId}/report/${roomReportId}`,
        method: 'DELETE',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(fetchRoomById(roomId, account_id, accessCode));
      dispatch(setNotice('Room Document Removed.'));
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(
          actionResponse,
          'payload.message',
          'Failed to remove room document.',
        ),
      }),
    );
    let err = new Error(
      actionResponse.payload?.message || 'Failed to remove room report',
    );
    throw err;
  };
};

export const addRoomUser = (body, roomId, account_id, accessCode) => {
  /**
   * body:
  {
    "room_id":1,
    "email":"brack@savvi.legal"
  }
   */
  const REQUEST = Types[`POST_ROOM_USER_REQUEST`];
  const SUCCESS = Types[`POST_ROOM_USER_SUCCESS`];
  const FAILED = Types[`POST_ROOM_USER_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/user/rooms/${roomId}/participants`,
        method: 'POST',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      dispatch(setNotice('User added.'));
      await dispatch(fetchRoomById(roomId, account_id, accessCode));
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(actionResponse, 'payload.message', 'Failed to add user to room.'),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to add user to room');
  };
};

export const updateRoomUser = (body, roomUserId, roomId, account_id) => {
  /**
   * body:
  {
    "invite_rights":0 // They can add people, allows them to hit post invite to invite user by email
  }
   */
  const REQUEST = Types[`PUT_ROOM_USER_REQUEST`];
  const SUCCESS = Types[`PUT_ROOM_USER_SUCCESS`];
  const FAILED = Types[`PUT_ROOM_USER_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/room/${roomId}/user/${roomUserId}`,
        method: 'PUT',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      await dispatch(fetchRoomById(roomId, account_id));
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(actionResponse, 'payload.message', 'Failed to update room user.'),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to update room user');
  };
};

export const deleteRoomUser = (roomUserId, roomId, account_id, roomCodeId) => {
  const REQUEST = Types[`DELETE_ROOM_USER_REQUEST`];
  const SUCCESS = Types[`DELETE_ROOM_USER_SUCCESS`];
  const FAILED = Types[`DELETE_ROOM_USER_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/room/${roomId}/user/${roomUserId}`,
        method: 'DELETE',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      if (roomCodeId) {
        return await dispatch(deleteRoomCode(roomCodeId, roomId, account_id));
      }
      await dispatch(fetchRoomById(roomId, account_id));
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(
          actionResponse,
          'payload.message',
          'Failed to remove user from room.',
        ),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to remove user from room');
  };
};

export const swapRoomLinks = (id1, id2, roomId, account_id, accessCode) => {
  const REQUEST = Types[`SWAP_ROOM_LINKS_REQUEST`];
  const SUCCESS = Types[`SWAP_ROOM_LINKS_SUCCESS`];
  const FAILED = Types[`SWAP_ROOM_LINKS_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/room/${roomId}/link/${id1}/swap/${id2}`,
        method: 'PATCH',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(setNotice('Room links swapped.'));
      await dispatch(fetchRoomById(roomId, account_id, accessCode));
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(actionResponse, 'payload.message', 'Failed to swap room links.'),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to swap room links.');
  };
};

export const swapRoomDocuments = (id1, id2, roomId, account_id, accessCode) => {
  const REQUEST = Types[`SWAP_ROOM_DOCUMENTS_REQUEST`];
  const SUCCESS = Types[`SWAP_ROOM_DOCUMENTS_SUCCESS`];
  const FAILED = Types[`SWAP_ROOM_DOCUMENTS_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/room/${roomId}/document/${id1}/swap/${id2}`,
        method: 'PATCH',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(setNotice('Room documents swapped.'));
      await dispatch(fetchRoomById(roomId, account_id, accessCode));
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(actionResponse, 'payload.message', 'Failed to swap room documents.'),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to swap room documents.');
  };
};

export const swapRoomReports = (id1, id2, roomId, account_id, accessCode) => {
  const REQUEST = Types[`SWAP_ROOM_REPORTS_REQUEST`];
  const SUCCESS = Types[`SWAP_ROOM_REPORTS_SUCCESS`];
  const FAILED = Types[`SWAP_ROOM_REPORTS_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/room/${roomId}/report/${id1}/swap/${id2}`,
        method: 'PATCH',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(setNotice('Room reports swapped.'));
      await dispatch(fetchRoomById(roomId, account_id, accessCode));
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(actionResponse, 'payload.message', 'Failed to swap room documents.'),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to swap room documents.');
  };
};

export const fetchRoomCodes = (roomId, accountId) => {
  const REQUEST = Types['FETCH_ROOM_CODES_REQUEST'];
  const SUCCESS = Types['FETCH_ROOM_CODES_SUCCESS'];
  const FAILED = Types['FETCH_ROOM_CODES_FAILED'];

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/room/${roomId}/code`,
        method: 'GET',
        headers: requestHeaders(true, accountId),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      dispatch(setRoomCodes(actionResponse.payload, roomId));
      return await actionResponse.payload;
    } else {
      dispatch(
        setNotice({
          type: 'error',
          message: actionResponse.payload?.message || 'failed to fetch Room codes',
        }),
      );
    }
  };
};

export const addRoomCode = (body, roomId, accountId) => {
  /**
   * body:
  {
    "room_id":1,
    "email":"brack@savvi.legal"
  }
   */
  const REQUEST = Types[`POST_ROOM_CODE_REQUEST`];
  const SUCCESS = Types[`POST_ROOM_CODE_SUCCESS`];
  const FAILED = Types[`POST_ROOM_CODE_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/room/${roomId}/code`,
        method: 'POST',
        headers: requestHeaders(true, accountId),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      dispatch(setNotice('Email with room link sent.'));
      await dispatch(fetchRoomCodes(roomId, accountId));
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(
          actionResponse,
          'payload.message',
          'An issue occurred while sharing room link. If issue continues, please contact support.',
        ),
      }),
    );
    throw await get(
      actionResponse,
      'payload.message',
      'An issue occurred while sharing room link. If issue continues, please contact support.',
    );
  };
};

export const deleteRoomCode = (roomCodeId, roomId, account_id, fetchRoomCodes = true) => {
  const REQUEST = Types[`DELETE_ROOM_CODE_REQUEST`];
  const SUCCESS = Types[`DELETE_ROOM_CODE_SUCCESS`];
  const FAILED = Types[`DELETE_ROOM_CODE_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/room/${roomId}/codes/${roomCodeId}`,
        method: 'DELETE',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      if (fetchRoomCodes) {
        await dispatch(fetchRoomCodes(roomId, account_id));
      }
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(
          actionResponse,
          'payload.message',
          'Failed to remove user from room.',
        ),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to remove user from room');
  };
};

export const fetchRoomAccessLog = (roomId, accId) => {
  const REQUEST = Types['FETCH_ROOM_ACCESS_LOG_REQUEST'];
  const SUCCESS = Types['FETCH_ROOM_ACCESS_LOG_SUCCESS'];
  const FAILED = Types['FETCH_ROOM_ACCESS_LOG_FAILED'];

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/user/rooms/${roomId}/access-log`,
        method: 'GET',
        headers: requestHeaders(true, accId || 'user'),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      dispatch(setRoomAccessLog(actionResponse.payload, roomId));
      return await actionResponse.payload;
    } else {
      dispatch(
        setNotice({
          type: 'error',
          message: actionResponse.payload?.message || 'failed to fetch Room access logs',
        }),
      );
    }
  };
};

export const fetchRoomHistory = (accessCode, roomId) => {
  const REQUEST = Types['FETCH_ROOM_HISTORY_REQUEST'];
  const SUCCESS = Types['FETCH_ROOM_HISTORY_SUCCESS'];
  const FAILED = Types['FETCH_ROOM_HISTORY_FAILED'];

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/guest/rooms/${accessCode}/history`,
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      dispatch(setRoomHistory(actionResponse.payload, roomId));
      return await actionResponse.payload;
    } else {
      dispatch(
        setNotice({
          type: 'error',
          message: actionResponse.payload?.message || 'failed to fetch Room history',
        }),
      );
    }
  };
};

export const createRoomHistory = (accessCode, body, roomId, accId) => {
  const REQUEST = Types['POST_ROOM_HISTORY_REQUEST'];
  const SUCCESS = Types['POST_ROOM_HISTORY_SUCCESS'];
  const FAILED = Types['POST_ROOM_HISTORY_FAILED'];

  return async (dispatch, getState) => {
    const {
      Auth: {
        user: { isAuthenticated },
      },
    } = getState();
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/${isAuthenticated ? 'user' : 'guest'}/rooms/${
          isAuthenticated ? roomId : accessCode
        }/history`,
        method: 'POST',
        headers: requestHeaders(true, accId || 'user'),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      dispatch(fetchRoomHistory(accessCode, actionResponse.payload.room_id));
      return await actionResponse.payload;
    }
    const errMessage =
      actionResponse.payload?.message || 'failed to create Room history item';
    // dispatch(
    //   setNotice({
    //     type: 'error',
    //     message: errMessage,
    //   }),
    // );
    throw new Error(errMessage);
  };
};

export const archiveRoomHistory = (roomId, historyId) => {
  const REQUEST = Types['DELETE_ROOM_HISTORY_REQUEST'];
  const SUCCESS = Types['DELETE_ROOM_HISTORY_SUCCESS'];
  const FAILED = Types['DELETE_ROOM_HISTORY_FAILED'];

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/user/rooms/${roomId}/history/${historyId}`,
        method: 'DELETE',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      return await actionResponse.payload;
    } else {
      console.error('Error deleting Room History: ', actionResponse.error);
      throw await actionResponse.error;
      // dispatch(
      //   setNotice({
      //     type: 'error',
      //     message: actionResponse.payload?.message || 'failed to delete Room history',
      //   }),
      // );
    }
  };
};

export const fetchRoomParticipants = (roomId, accId) => {
  const REQUEST = Types['FETCH_ROOM_PARTICIPANTS_REQUEST'];
  const SUCCESS = Types['FETCH_ROOM_PARTICIPANTS_SUCCESS'];
  const FAILED = Types['FETCH_ROOM_PARTICIPANTS_FAILED'];

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/user/rooms/${roomId}/participants`,
        method: 'GET',
        headers: requestHeaders(true, accId || 'user'),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      dispatch(setRoomParticipants(actionResponse.payload, roomId));
      return await actionResponse.payload;
    } else {
      dispatch(
        setNotice({
          type: 'error',
          message: actionResponse.payload?.message || 'failed to fetch Room access logs',
        }),
      );
    }
  };
};

/** *** End of DataRoom Editing */

export const fetchRoomToken = access_code => {
  const REQUEST = Types[`FETCH_ROOM_TOKEN_REQUEST`];
  const SUCCESS = Types[`FETCH_ROOM_TOKEN_SUCCESS`];
  const FAILED = Types[`FETCH_ROOM_TOKEN_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/guest/rooms/${access_code}/token`,
        method: 'GET',
        headers: requestHeaders(),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(setRoomToken(actionResponse.payload, access_code));
      return await actionResponse.payload;
    }
    throw await get(
      actionResponse,
      'payload.message',
      'This form has already been completed.  Please contact technical support if you believe this is in error.',
    );
  };
};

export const fetchBoxLinkById = (folderId, accountId) => {
  const REQUEST = Types['FETCH_LINK_BY_ID_REQUEST'];
  const SUCCESS = Types['FETCH_LINK_BY_ID_SUCCESS'];
  const FAILED = Types['FETCH_LINK_BY_ID_FAILED'];

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/shared_link/${folderId}`,
        method: 'GET',
        headers: requestHeaders(true, accountId),
        types: [REQUEST, SUCCESS, FAILED],
        bailout: state => state.Data.isFetching,
      },
    });
    if (!actionResponse.error) {
      if (!actionResponse.payload.url) {
        dispatch(
          setNotice({
            type: 'error',
            message:
              'Unable to fetch folder link. If problem persists, please message our support team.',
          }),
        );
      }
      return await actionResponse.payload;
    } else {
      dispatch(
        setNotice({
          type: 'error',
          message: actionResponse.payload?.message || 'failed to fetch Box link',
        }),
      );
      throw actionResponse.payload;
    }
  };
};

const getDataRoomLink = () => {
  const REQUEST = Types['GET_LINK_REQUEST'];
  const SUCCESS = Types['GET_LINK_SUCCESS'];
  const FAILED = Types['GET_LINK_FAILED'];

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/shared_link',
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        bailout: state => state.Data.isFetching,
      },
    });
    if (!actionResponse) {
      return;
    }
    if (!actionResponse.error) {
      dispatch(setLink(actionResponse.payload));
    } else {
      dispatch(
        setNotice({
          type: 'error',
          message: actionResponse.payload?.message || 'failed to get Data Room link',
        }),
      );
    }
  };
};

const createDataRoomLink = () => {
  const REQUEST = Types['POST_LINK_REQUEST'];
  const SUCCESS = Types['POST_LINK_SUCCESS'];
  const FAILED = Types['POST_LINK_FAILED'];

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/shared_link',
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        bailout: state => state.Data.isFetching,
      },
    });
    if (!actionResponse.error) {
      dispatch(setLink(actionResponse.payload));
      dispatch(setNotice('Data Room link created/extended'));
    } else {
      dispatch(
        setNotice({
          type: 'error',
          message: actionResponse.payload?.message || 'failed to create Data Room link',
        }),
      );
    }
  };
};

const updateDataRoomLink = () => {
  const REQUEST = Types['UPDATE_LINK_REQUEST'];
  const SUCCESS = Types['UPDATE_LINK_SUCCESS'];
  const FAILED = Types['UPDATE_LINK_FAILED'];

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/shared_link',
        method: 'PUT',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        bailout: state => state.Data.isFetching,
      },
    });
    if (!actionResponse.error) {
      dispatch(setLink(actionResponse.payload));
      dispatch(setNotice('New Data Room link created'));
    } else {
      dispatch(
        setNotice({
          type: 'error',
          message: actionResponse.payload?.message || 'failed to update Data Room link',
        }),
      );
    }
  };
};

export { createDataRoomLink, getDataRoomLink, updateDataRoomLink };
