const COACH_WORKFLOW_TEMPLATES_IDS = [76, 148, 149, 176];
const TRUSTEE_WORKFLOW_TEMPLATES_IDS = [179];
const ATTORNEY_WORKFLOW_TEMPLATES_IDS = [200];
const PARTY_WORKFLOW_TEMPLATES_IDS = [203];
const CO_FOUNDER_TEMPLATES_IDS = [205];

export default (workflowTemplateId, isPlural = false, shouldDefaultToInvestor = true) => {
  let portalType =
    (COACH_WORKFLOW_TEMPLATES_IDS.includes(workflowTemplateId) && 'Coach') ||
    (TRUSTEE_WORKFLOW_TEMPLATES_IDS.includes(workflowTemplateId) && 'Trustee') ||
    (ATTORNEY_WORKFLOW_TEMPLATES_IDS.includes(workflowTemplateId) && 'Attorney') ||
    (PARTY_WORKFLOW_TEMPLATES_IDS.includes(workflowTemplateId) && 'Party') ||
    (CO_FOUNDER_TEMPLATES_IDS.includes(workflowTemplateId) && 'Co-Founder') ||
    (!!shouldDefaultToInvestor && 'Investor') ||
    '';
  if (isPlural && !!portalType) {
    portalType += COACH_WORKFLOW_TEMPLATES_IDS.includes(workflowTemplateId) ? 'es' : 's';
  }
  return portalType;
};
