import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
// import startCase from 'lodash-es/startCase';

import { isFetchingWithHooks } from '../../redux/modules/Transaction/selectors';
// import InputStyledCheckbox from '../common/InputStyledCheckbox';
// import DocumentCredits from '../DocumentCredits';
import TimelineSection from '../TimelineSection';
import WorkbenchDocStatus from '../WorkbenchDocStatus';

import './TaskCard.scss';
// import { Button } from '../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PDFViewer } from '../OverviewCard/OverviewCard';
import { Button, Dropdown } from '../common';
import DocumentPreview from '../DocumentPreview';
import { get, startCase } from 'lodash-es';
import { PartnerCoverage } from '../../redux/modules/Project/selectors';
import mercuryBankStatusDict from '../../utils/MercuryBankStatusDict';
// import { startCase } from 'lodash-es';

const bem = elementName => `TaskCard${elementName ? '__' + elementName : ''}`;

const TaskCard = ({
  addons,
  brandMeta,
  fees,
  handleTaskAction,
  isAssessment,
  isDocLoadingFirstTime,
  isFetching,
  isFirst,
  isInitCollapsed,
  isLast,
  mercuryBankStatus,
  open,
  params,
  task,
}) => {
  const taskAddons = addons.filter(e => e.taskId === task.id);
  const taskFees = fees.filter(e => e.taskId === task.id);

  const {
    bypassed,
    document = {},
    id,
    label,
    mode,
    state,
    status_label,
    template: {
      deliverables,
      description,
      task_event,
      label: templateLabel,
      transaction_actions,
      type,
    },
    template_type: { customer_label: templateTypeLabel } = {},
    timeline,
  } = task;

  const { filename, panda_data, status } = document || {};
  const {
    name,
    redline_data: { redlining_revisions = [] } = {},
    redlining,
    suggestions = [],
  } = panda_data || {};

  const isFetchingTransaction = useSelector(isFetchingWithHooks);
  const partnerCoverage = useSelector(state => PartnerCoverage(state, params.projectId));
  const { feesCoveredBy } = partnerCoverage;

  const processLabel = filename || name || templateTypeLabel || label || templateLabel;

  const isRedliningShowing =
    status !== 'document.completed' && !!get(redlining, 'status', false);
  const isSuggestionsShowing =
    status !== 'document.completed' &&
    !!get(redlining, 'status', false) &&
    suggestions.length > 0;

  const isPreviewShowing = get(redlining_revisions, '[0].pages', []).length > 1;

  const { Bypass, Custom, Generate, Upload } = transaction_actions;
  const { high_days, low_days } = get(deliverables, '[0].timeline[0]', {});

  const isDocumentsShowing =
    !open &&
    !bypassed &&
    type === 'Document' &&
    (mode === 'Custom' ||
      mode === 'Generate' ||
      (mode === 'Upload' && !get(task_event, `modes[${mode}].skip_document`, false)));

  let taskMode = mode;
  let hideRadios = false;
  let hideActions = false;
  let timelineLabel = '';
  let statusLevel = '';
  let statusLabel =
    (status === 'document.declined' && 'Document Declined') ||
    (status === 'document.deleted' && 'Document Deleted') ||
    (isRedliningShowing && 'Awaiting Review') ||
    (mode === 'Upload' && state) ||
    (state === 'completed' && 'Completed') ||
    status_label ||
    state ||
    'Not Given';
  if (mercuryBankStatus) {
    statusLevel = mercuryBankStatusDict[mercuryBankStatus];
    statusLabel = startCase(mercuryBankStatus);
  } else if (status_label === 'Awaiting Signature' || isRedliningShowing) {
    statusLevel = 'high';
  } else if (!!bypassed) {
    statusLabel = 'Skipped';
  } else if (status_label === 'Pending') {
    statusLevel = 'med';
  } else if (state === 'incomplete') {
    statusLevel = 'high';
    statusLabel = 'Awaiting Submission';
  }

  /** handle timelineLabel */
  if (type === 'System' || mode === 'Upload') {
    timelineLabel = 'instant';
  } else if (low_days) {
    timelineLabel = `${low_days} - ${high_days} days`;
  }

  if (
    (!Bypass && !Generate && !Upload && !Custom) ||
    (Object.keys(transaction_actions).length < 2 && !Bypass) ||
    !open
  ) {
    hideActions = true;
  }

  if (!Generate && !Upload && !Custom) {
    hideRadios = true;
  }

  const [isActionsShowing, setIsActionsShowing] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(isInitCollapsed);
  const [selectedRedlineIndex, setSelectedRedlineIndex] = useState(0);
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div
      className={classnames(bem(), { last: isLast || !open, first: isFirst || !open })}
    >
      <div className={bem('top')}>
        <Button
          buttonType="icon"
          className={`${bem('toggle')} ${isCollapsed ? 'collapsed' : ''} ${
            isFocused ? 'isFocused' : ''
          }`}
          onMouseLeave={e => {
            setIsFocused(false);
          }}
          onClick={e => {
            e.stopPropagation();
            setIsCollapsed(!isCollapsed);
            setIsFocused(true);
          }}
        >
          <FontAwesomeIcon icon="chevron-down" />
        </Button>
        <div className={bem('topLabel')}>
          <h2>{label}</h2>
        </div>
        <div className={bem('topRight')}>
          <div className={bem(`topStatus ${statusLevel}`)}>{startCase(statusLabel)}</div>
          {!hideActions && (
            <div className={bem('topAction')}>
              <Button
                buttonType="icon"
                size="md"
                onClick={e => {
                  e.stopPropagation();
                  setIsActionsShowing(!isActionsShowing);
                }}
              >
                <FontAwesomeIcon icon="ellipsis-v" />
              </Button>
              {isActionsShowing && (
                <Dropdown
                  onClose={e => {
                    e.stopPropagation();
                    setIsActionsShowing(false);
                  }}
                >
                  {Object.entries(transaction_actions)
                    .sort((a, b) => {
                      if (a[0] === 'Generate') {
                        return -1;
                      }
                      if (
                        (a[0] === 'Upload' && b[0] !== 'Generate') ||
                        (a[0] === 'Skip' && b[0] === 'Custom')
                      ) {
                        return -1;
                      }
                      return 1;
                    })
                    .map(([key, value], index) => {
                      if (
                        hideRadios &&
                        (key === 'Generate' || key === 'Upload' || key === 'Custom')
                      ) {
                        return null;
                      }
                      let isDisabled = false;
                      let checked = taskMode === key;
                      let inputLabel = key;
                      if (hideRadios) {
                        checked = !!bypassed;
                      }
                      if (key === 'Bypass') {
                        isDisabled = !value;
                        inputLabel = 'Skip';
                      }
                      return (
                        <Button
                          key={`deliverable-type-${key}`}
                          buttonType="secondary"
                          isDisabled={
                            !!checked ||
                            !open ||
                            isDisabled ||
                            isFetching ||
                            isFetchingTransaction
                          }
                          onClick={() => {
                            if (hideRadios && key === 'Bypass' && taskMode === key) {
                              handleTaskAction('Enable', id);
                            } else if (taskMode !== key && value) {
                              handleTaskAction(key, id);
                            }
                          }}
                        >
                          {inputLabel}
                        </Button>
                      );
                    })}
                </Dropdown>
              )}
            </div>
          )}
        </div>
      </div>
      {!isCollapsed && (
        <div className={bem('body')}>
          <div className={bem('left')}>
            <div className={bem('section')}>
              <h4>
                {type}
                {type === 'Document' && mode === 'Upload' && ' (Upload)'}
                {type !== 'Document' && !!timelineLabel && (
                  <>
                    {' ('}
                    {timelineLabel})
                  </>
                )}
              </h4>
              {<p>{processLabel}</p>}
            </div>
            {taskFees.length > 0 && (
              <div className={bem('section')}>
                <h4>Fees</h4>
                {taskFees.map((fee, feeInd) => (
                  <div key={`product-fee-${feeInd}`}>
                    <p>
                      <strong>
                        {(!!feesCoveredBy && `Covered by ${feesCoveredBy} - `) ||
                          (fee.is_annual && fee.amount) ||
                          '$' + fee.amount + ' '}
                      </strong>
                      {fee.label}
                      <span className={(!feesCoveredBy && open && 'unpaid') || 'paid'}>
                        {(!!feesCoveredBy && '') || (open && ' Unpaid') || ' Paid'}
                      </span>
                    </p>
                  </div>
                ))}
              </div>
            )}
            {taskAddons.length > 0 && (
              <div className={bem('section')}>
                <h4>Add-ons</h4>
                {taskAddons.map((addon, addonInd) => (
                  <div key={`product-addon-${addonInd}`}>
                    <p>
                      <strong>
                        {(!!feesCoveredBy && `Covered by ${feesCoveredBy} -`) ||
                          '$' + addon.amount + '/'}
                        {(!!feesCoveredBy && ' ') ||
                          (addon.period === 'year' && 'Yr ') ||
                          'Mo '}
                      </strong>
                      {addon.label}
                      {' add-on '}
                      <span className={(!feesCoveredBy && open && 'unpaid') || 'paid'}>
                        {(!!feesCoveredBy && '') || (open && 'Unpaid') || 'Paid'}
                      </span>
                    </p>
                  </div>
                ))}
              </div>
            )}
            {isDocumentsShowing && (
              <WorkbenchDocStatus
                document={document}
                isDocLoadingFirstTime={isDocLoadingFirstTime}
                isTaskView
                taskMode={mode}
                taskState={state}
                setSelectedRedlineIndex={setSelectedRedlineIndex}
                params={params}
              />
            )}
            {!open &&
              !bypassed &&
              mode !== 'Upload' &&
              type === 'Document' &&
              (!!document.box_file_id || isPreviewShowing) && (
                <TimelineSection
                  deliverableLabel={label}
                  params={params}
                  timeline={timeline.slice().reverse()}
                />
              )}
          </div>
          <div className={bem('right')}>
            {type === 'Document' &&
              mode !== 'Upload' &&
              document.box_file_id &&
              !isRedliningShowing && (
                <PDFViewer
                  file={{ ...document, customLabel: 'Document' }}
                  isLinkOnHover
                  height={360}
                  width={320}
                />
              )}
            {!document.box_file_id && isPreviewShowing && !isRedliningShowing && (
              <DocumentPreview pages={redlining_revisions[0].pages} isThumbnailShowing />
            )}
            {isRedliningShowing && (
              <DocumentPreview
                label={
                  isSuggestionsShowing
                    ? 'View Original Document'
                    : `View Document Version ${selectedRedlineIndex + 1}`
                }
                pages={redlining_revisions[selectedRedlineIndex]?.pages}
                isThumbnailShowing
              />
            )}
            {!open &&
              !bypassed &&
              mode !== 'Upload' &&
              (type !== 'Document' || (!document.box_file_id && !isPreviewShowing)) && (
                <TimelineSection
                  deliverableLabel={label}
                  params={params}
                  timeline={timeline.slice().reverse()}
                />
              )}
            {(open || mode === 'Upload') &&
              !document.box_file_id &&
              !isPreviewShowing &&
              !!description && (
                <div className={bem('section')}>
                  <h4>description</h4>
                  {<p>{description}</p>}
                </div>
              )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskCard;
